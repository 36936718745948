import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "../../../components";
import style from "./home.nav.module.scss";

const HomeNav = ({ onClick = (e) => {} }: { onClick: (e: any) => void }) => {
  const [menu, toggleMenu] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const onClickL = useCallback((e: any) => {
    onClick(e);
    toggleMenu(false);
  }, []);

  const onFreeTrial = () => {
    window.open(process.env.REACT_APP_TEST_ROOM_BASE + "signup", "_blank");
  };

  const onClickExtenral = (link: any) => {
    window.open(link, "_blank");
  }

  return (
    <div className={style.menuContainer}>
      <div
        onClick={() => toggleMenu((prev) => !prev)}
        className={[style.sShow, style.mobNav].join(" ")}
      >
        Menu
      </div>
      <ul className={`${style.sHidden} ${menu ? style.sShow : ""}`}>
        <li onClick={onClickL} data-url="/view-plans">
          View plans
          {splitLocation[1] === "view-plans" ? (
            <div className={style.border}></div>
          ) : null}
        </li>

        <li onClick={() => onClickExtenral(`${process.env.REACT_APP_TEST_ROOM_BASE}signup`)}>
          {/* <a
            href={`${process.env.REACT_APP_TEST_ROOM_BASE}signup`}
            target="_blank"
            className="external"
            rel="noreferrer"
          > */}
            Sign up
          {/* </a> */}
        </li>
        <li onClick={() => onClickExtenral(`${process.env.REACT_APP_TEST_ROOM_BASE}signin`)}>
          {/* <a
            href={`${process.env.REACT_APP_TEST_ROOM_BASE}signin`}
            // href=""
            target="_blank"
            className="external"
            rel="noreferrer"
          > */}
            Log in
          {/* </a> */}
        </li>

        { <li>
          {" "}
          <Button className={style.freeTrialBtn} onClick={onFreeTrial}>
            Start Your Free Trial
          </Button>
        </li> }
      </ul>
    </div>
  );
};

export default HomeNav;
